import React from "react";
import {
  Chip,
  ChipGroup,
  Divider,
  Select,
  SelectGroup,
  SelectOption,
  SelectList,
  MenuToggle,
  MenuToggleElement,
  TextInputGroup,
  TextInputGroupMain,
  TextInputGroupUtilities,
  Button,
} from "@patternfly/react-core";
import TimesIcon from "@patternfly/react-icons/dist/esm/icons/times-icon";
import { PlaceObj } from "../types/ski-types";

const getNameFromId = (
  items: PlaceObj[],
  id: string | undefined | null
): string => {
  // filter items for is where name === id or short_name === id or id === id
  if (!id || items.length === 0) {
    return "";
  }
  const filtered = items.filter(
    (item) =>
      item.name === id || item.short_name === id || item.id === parseInt(id, 10)
  );
  return filtered.length > 0 ? filtered[0].short_name : "";
};

export function Pf5SelectLocation(props: Pf5LocationDropdownProps) {
  const {
    onChange,
    onClear = () => {},
    locationValue = [],
    useId = false,
    favs,
    selectOnlyOne = false,
  } = props;

  const [isOpen, setIsOpen] = React.useState(false);
  const [items, setItems] = React.useState<PlaceObj[]>([]);
  const [filterItems, setFilterItems] = React.useState<PlaceObj[]>([]);
  const [favorites, setFavorites] = React.useState<string[]>(favs || []);
  const [filterValue, setFilterValue] = React.useState<string>("");
  const [inputValue, setInputValue] = React.useState<string>(locationValue[0]);

  React.useEffect(() => {
    fetch(`https://lucky13s.com/apiv2/?/places/`)
      .then((res) => res.json())
      .then((res) => {
        setItems(res?.data);
        setInputValue(getNameFromId(res?.data, locationValue[0]));
      })
      // eslint-disable-next-line no-console
      .catch(console.log);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!favs) {
      const tempFavItems: string[] = [];
      items.forEach((item) => {
        if (item.status === "FAVORITE") {
          tempFavItems.push(item.name);
        }
      });
      setFavorites([...favorites, ...tempFavItems]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, favs]);

  React.useEffect(() => {
    let newSelectOptions: PlaceObj[] = [];
    // Filter menu items based on the text input value when one exists
    if (filterValue) {
      newSelectOptions = items.filter(
        (item: PlaceObj) =>
          String(item.name).toLowerCase().includes(filterValue.toLowerCase()) ||
          String(item.short_name)
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          String(item.id).toLowerCase().includes(filterValue.toLowerCase())
      );

      // Open the menu when the input value changes and the new value is not empty
      if (!isOpen) {
        setIsOpen(true);
      }
    } else {
      newSelectOptions = items;
    }
    setFilterItems(newSelectOptions);
  }, [filterValue, isOpen, items]);

  const onTextInputChange = (
    _event: React.FormEvent<HTMLInputElement>,
    value: string
  ) => {
    setFilterValue(value);
    setInputValue(value);
  };
  const onToggleClick = () => {
    setIsOpen(!isOpen);
  };
  const toggle = (toggleRef: React.Ref<MenuToggleElement>) => (
    <MenuToggle
      ref={toggleRef}
      variant="typeahead"
      onClick={onToggleClick}
      isExpanded={isOpen}
      style={
        {
          width: "300px",
        } as React.CSSProperties
      }
      className="text-input-group__light"
    >
      <TextInputGroup className="text-input-group__light">
        <TextInputGroupMain
          value={inputValue}
          onClick={onToggleClick}
          onChange={onTextInputChange}
          id="typeahead-select-location"
          autoComplete="off"
          placeholder="Select a ski area"
          role="combobox"
          isExpanded={isOpen}
          aria-controls="select-typeahead-listbox"
        >
          {!selectOnlyOne && (
            <ChipGroup aria-label="Current selections">
              {locationValue?.map((selection) => (
                <Chip
                  key={`${selection}-season-chip`}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    onSelect(ev, selection);
                  }}
                >
                  {selection}
                </Chip>
              ))}
            </ChipGroup>
          )}
        </TextInputGroupMain>
        <TextInputGroupUtilities>
          {locationValue.length > 0 && (
            <Button
              variant="plain"
              onClick={() => {
                setIsOpen(false);
                onChange("");
                setFilterValue("");
                setInputValue("");
                onClear();
              }}
              aria-label="Clear input value"
            >
              <TimesIcon aria-hidden />
            </Button>
          )}
        </TextInputGroupUtilities>
      </TextInputGroup>
    </MenuToggle>
  );

  const onSelect = (
    event?: React.MouseEvent<Element, MouseEvent> | undefined,
    value?: string | number | undefined
  ) => {
    setIsOpen(!isOpen);
    setFilterValue("");
    setInputValue(selectOnlyOne ? getNameFromId(items, value as string) : "");
    onChange(value);
  };

  const onFavorites = (event?: any, itemId?: any, actionId?: any) => {
    event.stopPropagation();
    if (favorites.includes(itemId)) {
      setFavorites(favorites.filter((id) => id !== itemId));
    } else {
      setFavorites([...favorites, itemId]);
    }
  };

  const selectOptions = [
    <SelectGroup
      label="All Places"
      key="group-all-place"
      className="select-group-ski-place"
    >
      <SelectList
        id="select-typeahead-listbox-places"
        key="select-typeahead-listbox-places"
      >
        {filterItems.map((item) => (
          <SelectOption
            key={item.name}
            value={useId ? item.id : item.short_name}
            id={item.name}
            isFavorited={
              favorites.includes(item.short_name) ||
              favorites.includes(`${item.id}`)
            }
          >
            {item.name}
          </SelectOption>
        ))}
      </SelectList>
    </SelectGroup>,
  ];

  const createFavorites = (favIds: string[]) => {
    const favoriteOptions: React.ReactElement[] = [];
    filterItems.forEach((item) => {
      if (favIds.includes(item.short_name) || favIds.includes(`${item.id}`)) {
        favoriteOptions.push(
          <SelectOption
            key={`${item.name}-fav-item`}
            value={useId ? item.id : item.short_name}
            id={item.name}
            isFavorited={
              favorites.includes(item.short_name) ||
              favorites.includes(`${item.id}`)
            }
          >
            {item.name}
          </SelectOption>
        );
      }
    });
    return favoriteOptions;
  };

  return (
    <Select
      id="typeahead-select-location"
      isOpen={isOpen}
      selected={locationValue}
      onSelect={onSelect}
      onOpenChange={() => {
        setIsOpen(false);
      }}
      toggle={toggle}
      onActionClick={onFavorites}
      isScrollable
    >
      {filterValue === "" && favorites.length > 0 && (
        <React.Fragment key="frag-fav-group">
          <SelectGroup
            key="favorites-group"
            label="Favorites"
            className="select-group-ski-place"
          >
            <SelectList key="fav-options">
              {createFavorites(favorites)}
            </SelectList>
          </SelectGroup>
          <Divider key="favorites-divider" className="" />
        </React.Fragment>
      )}
      {selectOptions}
    </Select>
  );
}

export type Pf5LocationDropdownProps = {
  onChange: Function;
  onClear?: Function;
  locationValue?: string[];
  useId?: boolean;
  favs?: string[];
  selectOnlyOne?: boolean;
};
