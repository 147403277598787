import React from "react";
import "@patternfly/react-core/dist/styles/base.css";
import "./App.css";
import { CalcForm } from "./components/calc-form";

const App: React.FC = () => {
  return (
    <>
      <h1>Vertical Calculator</h1>
      <div className="App">
        <CalcForm showVertical showLocationDropdown showAddLiftButton />
      </div>
    </>
  );
};

export default App;
