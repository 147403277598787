import React from "react";
import { Button, Flex, Text, TextContent } from "@patternfly/react-core";
import {
  MinusCircleIcon,
  PlusCircleIcon,
  TimesIcon,
} from "@patternfly/react-icons";
import { AddLiftsForm } from "./add-lifts";
import { LiftObj } from "../types/ski-types";
import { ChairLiftIcon, ChairLiftStatusIcon } from "./iconHelper";

type LiftCounts = Record<string, number>;

const MISC_VERT_INDEX = 1000000; // I am hoping we never have more than 1 million lifts
const MISC_VERT_STRING = "misc-vert";

export function LiftView(props: LiftViewProps) {
  const {
    placeName,
    showListOnly = false,
    showAddLiftButton = false,
    onChange,
    liftRides,
  } = props;

  const [liftCountData, setLiftCountData] = React.useState<LiftCounts>();
  const [lifts, setLifts] = React.useState<LiftObj[]>([]);
  const [msg, setMsg] = React.useState("");

  React.useEffect(() => {
    fetch(`https://lucky13s.com/apiv2/?/liftsForPlace/${placeName}`)
      .then((res) => res.json())
      .then((res) => {
        setLifts(res?.data);
      })
      // eslint-disable-next-line no-console
      .catch(console.log);
    setMsg("");
  }, [placeName, msg]);

  React.useEffect(() => {
    if (lifts.length !== 0) {
      const newliftCountData: LiftCounts = {};
      if (liftRides) {
        const rides = liftRides.split(",");
        rides.forEach((ride) => {
          const [liftId, count] = ride.split(":");
          if (liftId === MISC_VERT_STRING) {
            newliftCountData[MISC_VERT_INDEX] = parseInt(count, 10);
          } else {
            newliftCountData[parseInt(liftId, 10)] = parseInt(count, 10);
          }
        });
      }
      setLiftCountData(newliftCountData);
    }
    // eslint-disable-next-line
  }, [lifts]);

  const calculateVert = (countData: LiftCounts) => {
    let total = countData[MISC_VERT_INDEX] ? countData[MISC_VERT_INDEX] : 0;
    let liftRideDetail = "";
    lifts.forEach((lift: LiftObj) => {
      let sum = 0;
      const vert = parseInt(lift.lift_vertical, 10);
      let count = 0;
      if (countData?.[lift.lift_id] && countData[lift.lift_id] > 0) {
        count = countData[lift.lift_id];
        liftRideDetail += `${lift.lift_id}:${count},`;
      }
      sum = count * vert;
      total += sum;
    });
    liftRideDetail += countData[MISC_VERT_INDEX]
      ? `${MISC_VERT_STRING}:${countData[MISC_VERT_INDEX]},`
      : `${MISC_VERT_STRING}:0,`;
    return { total, liftRideDetail };
  };

  const onMiscVertChange = (vert: number) => {
    const newliftCountData: LiftCounts = { ...liftCountData };
    newliftCountData[MISC_VERT_INDEX] = vert;
    setLiftCountData(newliftCountData);
    if (onChange) onChange(calculateVert(newliftCountData));
  };

  const handleIncrementCount = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { id } = event.currentTarget;
    const newliftCountData: LiftCounts = { ...liftCountData };
    newliftCountData[id] = newliftCountData[id] ? newliftCountData[id] + 1 : 1;
    setLiftCountData(newliftCountData);
    if (onChange) onChange(calculateVert(newliftCountData));
  };

  const handleDecrementCount = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { id } = event.currentTarget;
    const newliftCountData: LiftCounts = { ...liftCountData };
    if (newliftCountData[id] && newliftCountData[id] > 0) {
      newliftCountData[id] -= 1;
      setLiftCountData(newliftCountData);
      if (onChange) onChange(calculateVert(newliftCountData));
    }
  };

  const handleClearCount = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { id } = event.currentTarget;
    const newliftCountData: LiftCounts = { ...liftCountData };
    newliftCountData[id] = 0;
    setLiftCountData(newliftCountData);
    if (onChange) onChange(calculateVert(newliftCountData));
  };

  const onStatusMsgChange = (_msg: string) => {
    setMsg(_msg);
  };

  const buildLiftView = () => {
    const liftElements: any[] = [];
    lifts.forEach((lift: LiftObj) => {
      liftElements.push(
        showListOnly ? (
          <TextContent key={`lift-${lift.lift_name}`}>
            <Flex>
              <ChairLiftStatusIcon type={lift.status} size="sm" />
              {"    "}
              <ChairLiftIcon type={lift.type} size="sm" />
              <AddLiftsForm
                lift={lift}
                place={placeName}
                statusMsg={onStatusMsgChange}
              />{" "}
              : {lift.lift_vertical} ft{" "}
            </Flex>
            <br />
          </TextContent>
        ) : (
          <div key={lift.lift_id} className="pf-c-form__group">
            <span className="pf-c-form__label-text">
              <ChairLiftIcon type={lift.type} size="sm" /> {"    "}
              {lift.lift_name}: ({lift.lift_vertical} ft){" "}
              <ChairLiftStatusIcon type={lift.status} size="sm" />
            </span>
            <div className="pf-c-form__horizontal-group">
              <Button
                id={lift.lift_id}
                variant="link"
                icon={<MinusCircleIcon />}
                onClick={handleDecrementCount}
              />
              {liftCountData?.[lift.lift_id] || "0"}
              <Button
                id={lift.lift_id}
                variant="link"
                icon={<PlusCircleIcon />}
                onClick={handleIncrementCount}
              />
              <Button
                id={lift.lift_id}
                variant="link"
                icon={<TimesIcon />}
                onClick={handleClearCount}
              />
            </div>
          </div>
        )
      );
    });
    return liftElements;
  };

  return (
    <div>
      <form
        className="pf-c-form pf-m-horizontal"
        onSubmit={(e) => e.preventDefault()}
      >
        {buildLiftView()}
        {!showListOnly && (
          <div className="pf-c-form__group">
            <span className="pf-c-form__label-text">Miscellaneous</span>
            <div className="pf-c-form__horizontal-group">
              {" "}
              <input
                id="misc-vert"
                value={liftCountData?.[MISC_VERT_INDEX] || "0"}
                onChange={(event) =>
                  onMiscVertChange(parseInt(event.target.value, 10))
                }
                inputMode="numeric"
              />
            </div>
          </div>
        )}
      </form>
      <br />
      <AddLiftsForm
        place={placeName}
        showAddLiftButton={showAddLiftButton}
        statusMsg={onStatusMsgChange}
      />
    </div>
  );
}

type LiftViewProps = {
  placeName: string;
  showListOnly?: boolean;
  showAddLiftButton: boolean;
  onChange?: Function;
  liftRides?: string;
};
