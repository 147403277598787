import React from "react";
import { Icon } from "@patternfly/react-core";
import {
  BanIcon,
  CheckIcon,
  CheckCircleIcon,
  CodeBranchIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  QuestionCircleIcon,
  RouteIcon,
} from "@patternfly/react-icons";
import { LiftStatus, LiftType, PlaceStatus } from "../types/ski-types";

export function ChairLiftIcon({
  type,
  size,
}: {
  type: LiftType;
  size?: "sm" | "lg";
}) {
  let iconName: string;
  switch (type) {
    case "Single":
      iconName = "double-chair.svg";
      break;
    case "Double":
      iconName = "double-chair.svg";
      break;
    case "Triple":
      iconName = "triple-chair.svg";
      break;
    case "Quad":
    case "High Speed Quad":
      iconName = "quad.svg";
      break;
    case "High Speed 6":
      iconName = "6packv3.svg";
      break;
    case "High Speed 8":
      iconName = "8packv3.svg";
      break;
    case "Gondola":
      iconName = "gondola1.svg";
      break;
    case "Chondola":
      iconName = "gondola1.svg";
      break;
    case "Tram":
      iconName = "tram.svg";
      break;
    case "Platter":
      iconName = "platter.svg";
      break;
    case "T-bar":
      iconName = "platter.svg";
      break;
    case "Surface":
      iconName = "magic-carpet.svg";
      break;
    default:
      iconName = "quad.svg";
      break;
  }
  const pxSize = size === "lg" ? "64px" : "20px";
  return (
    <img
      src={`https://lucky13s.com/vert/images/icons/${iconName}`}
      style={{ width: pxSize }}
      alt={iconName}
    />
  );
}

export function ChairLiftStatusIcon({
  type,
  size,
}: {
  type: LiftStatus;
  size?: "sm";
}) {
  switch (type) {
    case "Active":
      return (
        <Icon size={size} status="success">
          <CheckCircleIcon />
        </Icon>
      );
    case "Replaced":
      return (
        <Icon size={size} status="warning">
          <ExclamationTriangleIcon />
        </Icon>
      );
    case "Removed":
      return (
        <Icon size={size} status="danger">
          <ExclamationCircleIcon />
        </Icon>
      );
    case "Closed":
      return (
        <Icon size={size} status="danger">
          <BanIcon />
        </Icon>
      );
    default:
      return (
        <Icon size={size} status="info">
          <QuestionCircleIcon />
        </Icon>
      );
  }
}

export function SkiAreaStatusIcon({
  type,
  size,
}: {
  type: PlaceStatus;
  size?: "sm" | "lg" | "xl";
}) {
  switch (type) {
    case "OPEN":
      return (
        <Icon size={size} status="success">
          <CheckCircleIcon />
        </Icon>
      );
    case "FAVORITE":
      return (
        <Icon size={size} status="success">
          <CheckIcon />
        </Icon>
      );
    case "NAMECHANGED":
      return (
        <Icon size={size} status="warning">
          <ExclamationTriangleIcon />
        </Icon>
      );
    case "MERGED":
      return (
        <Icon size={size} status="info">
          <CodeBranchIcon />
        </Icon>
      );
    case "CLOSED":
      return (
        <Icon size={size} status="danger">
          <BanIcon />
        </Icon>
      );
    case "BCXC":
      return (
        <Icon size={size} status="custom">
          <RouteIcon />
        </Icon>
      );
    default:
      return (
        <Icon size={size} status="info">
          <QuestionCircleIcon />
        </Icon>
      );
  }
}
