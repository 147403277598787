import * as React from "react";
import "@patternfly/react-core/dist/styles/base.css";
import { Pf5SelectLocation } from "./pf5-select-location";
import { LiftView } from "./lift-view";
import { toggleValueInArray } from "../utils/helper";

export function CalcForm(props: CalcFormProps) {
  const {
    place,
    showVertical = false,
    showLocationDropdown = false,
    showAddLiftButton = false,
    liftRides = "",
  } = props;
  const [placeName, setPlaceName] = React.useState<string[]>(
    place ? [place] : ["Solitude"]
  );
  const [vertical, setVertical] = React.useState<number>(0);
  const updatePlaceName = (value: string) => {
    setPlaceName(toggleValueInArray(placeName, value));
  };

  const handleVertical = (verticalInfo: {
    total: number;
    liftRideDetail: string;
  }) => {
    setVertical(verticalInfo.total);
  };

  return (
    <div>
      {showVertical && (
        <div className="vert-horizontal">
          <span className="pf-c-form__label-text">Total Vertical: </span>
          <h3> {vertical} FT</h3>
          <br />
        </div>
      )}
      {showLocationDropdown && (
        <div className="add-padding-bottom">
          <Pf5SelectLocation
            locationValue={placeName}
            onChange={updatePlaceName}
          />
        </div>
      )}
      <LiftView
        placeName={placeName.join(",")}
        showAddLiftButton={showAddLiftButton}
        onChange={handleVertical}
        liftRides={liftRides}
      />
    </div>
  );
} // end CalcForm

export interface INameToValueMap {
  [key: string]: any;
}
export type CalcFormProps = {
  place?: string;
  showVertical?: boolean; // is the vert page in a modal
  showLocationDropdown?: boolean; // hides location dropdown
  showAddLiftButton?: boolean; // hide the add new lifts button
  liftRides?: string;
};
