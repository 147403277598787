import React from "react";
import {
  AlertActionCloseButton,
  Alert,
  Button,
  Form,
  FormGroup,
  FormSelect,
  FormSelectOption,
  Modal,
  ModalVariant,
  TextInput,
} from "@patternfly/react-core";
import { LiftObj } from "../types/ski-types";

export function AddLiftsForm(props: AddLiftsFormProps) {
  //   const [isExpanded, setIsExpanded] = React.useState(false);
  const { place, lift, showAddLiftButton = false, statusMsg } = props;
  const [liftName, setLiftName] = React.useState(lift?.lift_name || "");
  const [liftDesc, setLiftDesc] = React.useState(lift?.lift_desc ?? "");
  const [liftType, setLiftType] = React.useState(lift?.type || "");
  const [liftStatus, setLiftStatus] = React.useState(lift?.status || "");
  const [liftVertical, setLiftVertical] = React.useState(
    lift?.lift_vertical || 0
  );
  const [msg, setMsg] = React.useState("");
  const [isAddLiftModalOpen, setIsAddLiftModalOpen] = React.useState(false);

  const clearForm = () => {
    setLiftName("");
    setLiftVertical(0);
    setLiftDesc("");
  };
  const handleName = (v: string, e: any) => setLiftName(v);
  const handleType = (v: string, e: any) => setLiftType(v);
  const handleStatus = (v: string, e: any) => setLiftStatus(v);
  const handleDesc = (v: string, e: any) => setLiftDesc(v);
  const handleVert = (v: string, e: any) => {
    let value = 0;
    if (typeof parseInt(v, 10) === "number") {
      value = parseInt(v, 10);
    }
    setLiftVertical(value);
  };

  const handleModalToggle = () => {
    setIsAddLiftModalOpen(!isAddLiftModalOpen);
  };
  const localStatusMsg = (_msg: string) => {
    if (_msg.includes("Success")) {
      if (statusMsg) statusMsg("Updated");
      setMsg(_msg);
    } else {
      setMsg(_msg);
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Quick Validation:
    if (place === "" || liftName === "" || liftVertical === 0) {
      localStatusMsg("You need to set all required fields");
      return;
    }
    if (place.indexOf(",") > -1) {
      // Make sure we do not have a list of places
      localStatusMsg("Multiple Places not supported");
      return;
    }

    // build a data post
    const opts = {
      lift_id: lift?.lift_id || null,
      place_name: place,
      lift_name: liftName,
      lift_vert: liftVertical,
      lift_desc: liftDesc,
      type: liftType || "High Speed Quad",
      status: liftStatus || "Active",
      place_id: null,
    };
    const content = JSON.stringify(opts);
    // use fetch to send post
    const url = lift
      ? `https://lucky13s.com/apiv2/?/lifts/${lift?.lift_id}`
      : `https://lucky13s.com/apiv2/?/lifts`;

    fetch(url, {
      method: lift ? "PUT" : "POST",
      body: content,
    })
      .then((res) => res.json())
      .then((res) => {
        localStatusMsg(res?.msg);
        // clearForm();
      })
      // eslint-disable-next-line no-console
      .catch(console.log);
  };

  const typeOptions = [
    { value: "please choose", label: "Select one", disabled: true },
    { value: "High Speed Quad", label: "High Speed Quad", disabled: false },
    { value: "Single", label: "Single", disabled: false },
    { value: "Double", label: "Double", disabled: false },
    { value: "Triple", label: "Triple", disabled: false },
    { value: "Quad", label: "Quad", disabled: false },
    { value: "High Speed 6", label: "Six Pack", disabled: false },
    { value: "High Speed 8", label: "Eight Pack", disabled: false },
    { value: "Gondola", label: "Gondola", disabled: false },
    { value: "Chondola", label: "Chondola", disabled: false },
    { value: "Tram", label: "Tram", disabled: false },
    { value: "Platter", label: "Platter", disabled: false },
    { value: "T-Bar", label: "T-Bar", disabled: false },
    { value: "Surface", label: "Magic Carpet", disabled: false },
  ];
  const statusOptions = [
    { value: "please choose", label: "Select one", disabled: true },
    { value: "Active", label: "Active", disabled: false },
    { value: "Closed", label: "Closed", disabled: false },
    { value: "Removed", label: "Removed", disabled: false },
    { value: "Replaced", label: "Replaced", disabled: false },
  ];

  const clearMsg = () => setMsg("");

  return (
    <div>
      {showAddLiftButton && (
        <Button
          variant="primary"
          onClick={handleModalToggle}
          isDisabled={place === "" || place.indexOf(",") > -1}
        >
          Add New Lift
        </Button>
      )}
      {lift && ( // Edit existing lift
        <span onClick={() => {}} onKeyDown={() => {}} role="presentation">
          {lift.lift_name}
        </span>
      )}
      <Modal
        variant={ModalVariant.small}
        title={
          lift
            ? `Edit Lift ${lift?.lift_name} at ${place}`
            : `Add Lift to ${place}`
        }
        isOpen={isAddLiftModalOpen}
        onClose={handleModalToggle}
        actions={[
          <Button key="cancel" variant="secondary" onClick={handleModalToggle}>
            Done with lifts
          </Button>,
        ]}
      >
        {msg && (
          <Alert
            variant="info"
            isInline
            title={msg}
            timeout={5000}
            actionClose={<AlertActionCloseButton onClose={clearMsg} />}
          />
        )}
        <Form isHorizontal onSubmit={handleSubmit}>
          <FormGroup
            label="Lift Name"
            isRequired
            fieldId="horizontal-form-lift-name"
            //   helperText="Please provide full name of lift"
          >
            <TextInput
              value={liftName}
              isRequired
              type="text"
              id="lift-name"
              aria-describedby="lift-name"
              name="lift-name"
              onChange={(e, v: string) => handleName(v, e)}
            />
          </FormGroup>
          <FormGroup
            label="Lift Vertical"
            isRequired
            fieldId="lift-vertical-form"
            //   helperText="Vertical of lift in feet"
          >
            <TextInput
              value={liftVertical}
              isRequired
              type="text"
              id="lift-vert"
              aria-describedby="lift-vert"
              name="lift-vert"
              onChange={(e, v: string) => handleVert(v, e)}
            />
          </FormGroup>
          <FormGroup
            label="Lift Description"
            fieldId="horizontal-form-lift-desc"
          >
            <TextInput
              value={liftDesc}
              type="text"
              id="lift-desc"
              aria-describedby="lift-desc"
              name="lift-desc"
              onChange={(e, v: string) => handleDesc(v, e)}
            />
          </FormGroup>
          <FormGroup label="Type" fieldId="lift-type">
            <FormSelect
              value={liftType}
              onChange={(e, value: string) => handleType(value, e)}
              aria-label="type-select"
              ouiaId="BasicFormSelect"
            >
              {typeOptions.map((option, index) => (
                <FormSelectOption
                  isDisabled={option.disabled}
                  key={`typeOption-${option.value}`}
                  value={option.value}
                  label={option.label}
                />
              ))}
            </FormSelect>
          </FormGroup>

          <FormGroup label="Status" fieldId="lift-status">
            <FormSelect
              value={liftStatus}
              onChange={(e, value: string) => handleStatus(value, e)}
              aria-label="lift-status--select"
              ouiaId="BasicFormSelect"
            >
              {statusOptions.map((option, index) => (
                <FormSelectOption
                  isDisabled={option.disabled}
                  key={`typeOption-${option.value}`}
                  value={option.value}
                  label={option.label}
                />
              ))}
            </FormSelect>
          </FormGroup>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="danger" onClick={clearForm}>
            Clear
          </Button>
        </Form>
      </Modal>
    </div>
  );
}
export type AddLiftsFormProps = {
  place: string;
  lift?: LiftObj;
  showAddLiftButton?: boolean;
  statusMsg?: Function;
};
