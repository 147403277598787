// function takes in a string array and a string value, retunrs a new array with the
// value removed if it exists, or with the value if it does not exist
export const toggleValueInArray = (
  array: string[],
  value: string
): string[] => {
  if (array.includes(value)) {
    return array.filter((item) => item !== value);
  }
  return [...array, value];
};

export const toggleValueInNumberArray = (
  array: number[],
  value: number
): number[] => {
  if (array.includes(value)) {
    return array.filter((item) => item !== value);
  }
  return [...array, value];
};
